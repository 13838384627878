
#payment-provider-grid .input-container {
  position: relative;
  display: flex;
  height: 40px;
}

#payment-provider-grid .icon-container:last-child {
  right: 0;
}

#payment-provider-grid .icon-container.payment-method {
  right: 0;
}

#payment-provider-grid .input-container.expiry-date {
  margin-right: 4px;
}

#payment-provider-grid .input-container.cvv {
  margin-left: 4px;
}

#payment-provider-grid .card-number-frame {
  padding-left: 40px;
}

#payment-provider-grid .expiry-date-frame {
  padding-left: 40px;
}

#payment-provider-grid .cvv-frame {
  padding-left: 40px;
}

#payment-provider-grid div + button {
  margin-top: 8px;
}

#payment-provider-grid .icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

#payment-provider-grid .icon-container.payment-method  {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

#payment-provider-grid .icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

#payment-provider-grid .icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] { display: none }

#payment-provider-grid .frame {
  opacity: 0;
}

#payment-provider-grid .frame--activated {
  opacity: 1;
  border-bottom: 1px solid #CCC;
  width: 100%;
}

#payment-provider-grid .frame--activated.frame--focus {
  border-bottom: solid 1px #999999;
}

#payment-provider-grid .frame--activated.frame--invalid {
  border-bottom: solid 1px #EF2D34;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen, :root .icon-container {
  display: block;
}

_:-ms-fullscreen, :root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen, #icon-card-number,
_:-ms-fullscreen, #icon-expiry-date,
_:-ms-fullscreen, #icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}
