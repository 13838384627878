/* @import url("datepicker.css"); */

html, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 13px/1.4 Roboto, sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: initial;
}
strong{
  font-weight:500
}

div#prefixGrid span.flag-icon-lg, div#countryGrid span.flag-icon-lg{
  font-size: 1em
}

.css-d8oujb{
  display: none
}

[class*="indicatorSeparator"]{
  display: none
}

.css-1ep9fjw, .css-1uq0kb5{
  padding: 8px 1.5px !important;
}

.recurly-hosted-field{
   border-radius: 0;
   border-top: 0;
   border-left: 0;
   border-right: 0;
   padding: 0;
   min-height: 2.5rem;
}

.recurly-hosted-field-icon{
  margin-left:0;
  padding:5px;
}
.recurly-hosted-field-number {
  width: 100%;
}
.recurly-hosted-field.recurly-hosted-field-month {
  width: 90%;
}
.recurly-hosted-field.recurly-hosted-field-year{
  width: 90%;
}
.recurly-hosted-field.recurly-hosted-field-cvv{
  width: 100%;
}
#three-d-container > div {
  height: 100%;
}

.highlightText{
font-weight: 400;
}

.italicText{
  font-style: italic
}

.loadingApp #toggleTheme{
  display:none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.sfcModal-dialog {
  height: 100%;
}
.sfcModal-content {
  height: 100%;
}
.sfcModal-body {
  height: 100%;
}
.sfcModal-body iframe {
  height: 100% !important;
}

.livechat-bubble {
  margin-bottom: 0px;
  margin-right: 0px;
  min-width: 84px !important;
  min-height: 84px !important;
}

.displayNone { 
  display: none
}

.dashboard-image{
  width: 75% !important;
  height: unset !important;
  max-height: 600px;
  display: block;
  margin: 0 auto;
}

em.cbc_content {
  font-style: normal;
}

#adroll_consent_container {
  display: none
}

.closeLiveChat {
  position: absolute;
  right: 0;
  width: 5px;
  height: 5px;
  opacity: 0.7;
  cursor: pointer;
}
.closeLiveChat:hover {
  opacity: 1;
}
.closeLiveChat:before, .closeLiveChat:after {
  position: absolute;
  left: -15px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
}
.closeLiveChat:before {
  transform: rotate(45deg);
}
.closeLiveChat:after {
  transform: rotate(-45deg);
}
.closeLiveChatDark:before, .closeLiveChatDark:after {
  background-color: #fff;
}

@media only screen and (max-width: 600px) {
  .rccs__card{
    height: 148.73px;
    width: 235px;
  }
  .rccs__name{
    font-size:13px
  }
  .rccs__number{
    font-size:17px
  }
}
