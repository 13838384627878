.flag-icon-xk {
  background-image: url('./images/4x3/xk.svg');
}

.flag-icon-xk.flag-icon-squared {
  background-image: url('./images/1x1/xk.svg');
}

.flag-icon-an {
  background-image: url('./images/4x3/an.svg');
}

.flag-icon-an.flag-icon-squared {
  background-image: url('./images/1x1/an.svg');
}